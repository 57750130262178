/* eslint-disable */
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Notyf } from "notyf";
import 'notyf/notyf.min.css';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
const defaultTheme = createTheme();

export default function SignInSide() {
    const [ user, setUser ] = React.useState([]);
    const [ profile, setProfile ] = React.useState([]);
    const responseMessage = (response) => {
        console.log(response);
    };
    const errorMessage = (error) => {
        console.log(error);
    };
    
    const notyf = new Notyf({
        duration: 1000,
        position: {
            x: "right",
            y: "top",
        },
        types: [
            {
                type: "warning",
                background: "orange",
                icon: {
                    className: "material-icons",
                    tagName: "i",
                    text: "warning",
                },
            },
            {
                type: "error",
                background: "indianred",
                duration: 2000,
                dismissible: true,
            },
        ],
    });
    const login = useGoogleLogin({
        onSuccess: (codeResponse) => setUser(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
    });
    React.useEffect(
        () => {
            if (user) {
                axios
                    .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                            Accept: 'application/json'
                        }
                    })
                    .then((res) => {
                        axios.post(process.env.REACT_APP_API_URL + 'users/checkLogin2', {
                            email: res.data.email,
                            
                        }).then((res) => {
                            if (res.data.check == false) {
                                notyf.open({
                                    type: "error",
                                    message: "The account is invalid account !",
                                });
                               
                            }else if(res.data.check==true){
                                localStorage.setItem('token', res.data.token);
                                notyf.open({
                                    type: "success",
                                    message: "Thank you !",
                                });
                                setTimeout(() => {
                                    window.location.replace('/blogs');
                                }, 2000);
                            }
                        })
                        res.data.email
                    })
                    .catch((err) => console.log(err));
            }
        },
        [ user ]
    );
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if (data.get('email') == '') {
            notyf.open({
                type: "error",
                message: "Email is required",
            });
        } else if (data.get('password') == '') {
            notyf.open({
                type: "error",
                message: "Password is required",
            });
        } else {
            axios.post(process.env.REACT_APP_API_URL + 'users/checkLogin', {
                email: data.get('email'),
                password: data.get('password')
            }).then((res) => {
                if (res.data.check == false) {
                    notyf.open({
                        type: "error",
                        message: "The account is invalid account !",
                    });
                   
                }else if(res.data.check==true){
                    localStorage.setItem('token', res.data.token);
                    notyf.open({
                        type: "success",
                        message: "Thank you !",
                    });
                    setTimeout(() => {
                        window.location.replace('/blogs');
                    }, 2000);
                }
            })
        }
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: 'url(https://source.unsplash.com/random?wallpapers)',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            <FormControlLabel
                                control={<Checkbox value="remember" color="primary" />}
                                label="Remember me"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Sign In
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    <Link href="#" variant="body2">
                                        Forgot password?
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link href="#" variant="body2">
                                        {"Don't have an account? Sign Up"}
                                    </Link>
                                </Grid>
                            </Grid>
                            <div className="row">
                                <div className="col-md text-center">
                                <button className='btn btn-primary' onClick={login}><i class="bi bi-google"></i> </button>
                                </div>
                            </div>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}