/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import JoditEditor from 'jodit-react';
import Layout from '../components/Layout';
import { Dropzone, FileMosaic } from "@dropzone-ui/react";
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
function Blogs() {
    const [create, setCreate] = useState(false);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [blogs, setBlogs] = useState([]);
    const [files, setFiles] = useState([]);
    const [page, setPage] = useState(1);
    const [comments, setComment] = useState([]);
    const editor = useRef(null);
    const [pageList, setPageList] = useState([]);
    const notyf = new Notyf({
        duration: 1000,
        position: {
            x: 'right',
            y: 'top',
        },
        types: [
            {
                type: 'warning',
                background: 'orange',
                icon: {
                    className: 'material-icons',
                    tagName: 'i',
                    text: 'warning'
                }
            },
            {
                type: 'error',
                background: 'indianred',
                duration: 2000,
                dismissible: true
            },
            {
                type: 'success',
                background: 'success',
                duration: 2000,
                dismissible: true
            }
        ]
    });
    const switchCreate = () => {
        setCreate(!create);
    };
    const updateFiles = (incommingFiles) => {

        setFiles(incommingFiles);
    };
    const submitBlogs = () => {
        if (title == '') {
            notyf.open({
                type: "error",
                message: "Thiếu tiêu đề bài viết",
            });
        } else if (content == '') {
            notyf.open({
                type: "error",
                message: "Thiếu nội dung",
            });
        } else if (files.length == 0) {
            var formData = new FormData();
            formData.append('title', title);
            formData.append('content', content);
            axios.post(process.env.REACT_APP_API_URL + 'blogs', formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    Accept: "application/json",
                },
            }).then((res) => {
                if (res.data.check == true) {
                    notyf.open({
                        type: "success",
                        message: "Upload image successfully",
                    });
                    window.location.reload();
                } else if (res.data.check == false) {
                    if (res.data.msg) {
                        notyf.open({
                            type: "error",
                            message: res.data.msg,
                        });

                    }
                }
            });
        } else if (files.length > 0) {
            var formData = new FormData();
            files.forEach(file => {
                formData.append('files[]', file.file);
            });
            formData.append('title', title);
            formData.append('content', content);
            axios.post(process.env.REACT_APP_API_URL + 'blogs', formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    Accept: "application/json",
                },
            }).then((res) => {
                if (res.data.check == true) {
                    notyf.open({
                        type: "success",
                        message: "Upload image successfully",
                    });
                    window.location.reload();
                } else if (res.data.check == false) {
                    if (res.data.msg) {
                        notyf.open({
                            type: "error",
                            message: res.data.msg,
                        });

                    }
                }
            });
        }
    }
    useEffect(() => {
        if (localStorage.getItem('token')) {
            fetch(`${process.env.REACT_APP_API_URL}blogs?page=` + page, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            }).then((res) => res.json()).then((res) => {
                setBlogs(res.data);
                console.log(res.last_page);
                if (res.last_page > 1) {
                    var arr = [];
                    for (let i = 1; i <= res.last_page; i++) {
                        arr.push(i)
                    }
                    setPageList(arr);
                }
            })
        } else {
            window.location.replace('/');
        }
    }, [page])

    const config = {
        height: 400,
        width: '100%'
    }
    return (
        <>
            <Layout>
                <>
                    <div className="row">
                        <div className="col-md-1">
                            <button className='btn w-100 btn-primary' onClick={switchCreate}>Tạo</button>
                        </div>
                    </div>
                    <div className="row w-100">
                        <div className={create ? "col-md-9 mt-4" : "col-md"}>
                            <div class="card mt-3">
                                <div class="card-header">Blogs</div>
                                <div class="card-body">
                                    <ul>
                                        {blogs && blogs.length > 0 && blogs.map((item, index) => (
                                            <Link key={item.id} to={`/blogs/${item.id}`}>
                                                <li>{item.title}</li>
                                            </Link>
                                        ))}
                                    </ul>
                                    {pageList.length > 0 && pageList.map((item, index) => (
                                        <button className={item==page?'btn btn-primary me-2':'btn btn-outline-primary me-2  '} onClick={(e)=>setPage(item)} key={index}>{item}</button>
                                    ))}
                                </div>
                            </div>

                        </div>
                        {create && (

                            <div className="col-md">
                                <div className="col-md">
                                    <div className="row mb-3">
                                        <label htmlFor="title" className="form-label">Tiêu đề</label>
                                        <div className="input-group mb-3">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Tiêu đề"
                                                aria-label=""
                                                aria-describedby="title"
                                                onChange={(e) => setTitle(e.target.value)}
                                            />
                                            <button
                                                className="btn btn-outline-primary"
                                                type="button"
                                                id="title"
                                                onClick={(e) => submitBlogs()}
                                            >
                                                Thêm
                                            </button>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <JoditEditor
                                            ref={editor}
                                            config={config}
                                            value={content}
                                            tabIndex={1}
                                            onBlur={newContent => setContent(newContent)}
                                        />
                                    </div>
                                    <div className="row mb-3 ps-3">
                                        <Dropzone style={{ width: '90%' }} onChange={updateFiles} accept="image/*" value={files}>
                                            {files.slice(0, 3).map((file) => (
                                                <FileMosaic {...file} preview />
                                            ))}
                                        </Dropzone>
                                    </div>

                                </div>
                            </div>
                        )}
                    </div>
                </>
            </Layout>
        </>
    );
}

export default Blogs;
