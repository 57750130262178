/*eslint-disabled*/ 
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Blogs from './pages/Blogs';
import Single from './pages/Single';
import Notfound from './pages/Notfound';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {!localStorage.getItem('token') &&
            <Route path='/' element={<Login />} />          
          }
          {
            localStorage.getItem('token')&&
            <>
            <Route path='/' element={<Login />} />          
            <Route path='/blogs' element={<Blogs />} />
            <Route path='/blogs/:id' element={<Single />} />
            </>
          }
          <Route path='*' element={<Notfound/>}/>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
