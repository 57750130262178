/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import JoditEditor from 'jodit-react';
import Layout from '../components/Layout';
import { Dropzone, FileMosaic } from "@dropzone-ui/react";
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
import axios from 'axios';
import Swal from 'sweetalert2'
import { Link, useParams } from 'react-router-dom';
function Single() {
    const { id } = useParams();
    const [create, setCreate] = useState(false);
    const [content, setContent] = useState('');
    const [single, setSingle] = useState({});
    const [files, setFiles] = useState([]);
    const [gallery, setGallery] = useState([]);
    const [comments, setComment] = useState([]);
    const editor = useRef(null);
    const notyf = new Notyf({
        duration: 1000,
        position: {
            x: 'right',
            y: 'top',
        },
        types: [
            {
                type: 'warning',
                background: 'red !important',
                icon: {
                    className: 'material-icons',
                    tagName: 'i',
                    text: 'warning'
                }
            },
            {
                type: 'error',
                background: 'indianred',
                duration: 2000,
                dismissible: true
            },
            {
                type: 'success',
                background: 'success',
                duration: 2000,
                dismissible: true
            }
        ]
    });
    const switchCreate = () => {
        setCreate(!create);
    };
    const updateFiles = (incommingFiles) => {

        setFiles(incommingFiles);
    };
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}blogs/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            }
        }).then((res) => res.json()).then((res) => {
            setSingle(res.blogs);
            setGallery(res.gallery);
            setComment(res.comments);
        })
    }, [id])
    const deletePost =()=>{
        Swal.fire({
            icon:'question',
            text: "Xóa bài blog này ?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Đúng",
            denyButtonText: `Không`
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios.delete(process.env.REACT_APP_API_URL + 'blogs/'+single.id, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                        Accept: "application/json",
                    },
                }).then((res) => {
                    if(res.data.check==true){
                        notyf.open({
                            type: "success",
                            message: "Xóa thành công",
                        });
                        window.location.replace('/blogs');
                    }
                });
            } else if (result.isDenied) {
            }
          });
        console.log(single.id);
    }
    const submitBlogs = () => {
        if (content == '') {
            notyf.open({
                type: "error",
                message: "Thiếu nội dung",
            });
        } else if (files.length == 0) {
            var formData = new FormData();
            formData.append('comment', content);
            formData.append('idBlog', id);

            axios.post(process.env.REACT_APP_API_URL + 'blogs/comment', formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    Accept: "application/json",
                },
            }).then((res) => {
                if (res.data.check == true) {
                    notyf.open({
                        type: "success",
                        message: "Comment thành công",
                    });
                    window.location.reload();
                } else if (res.data.check == false) {
                    if (res.data.msg) {
                        notyf.open({
                            type: "error",
                            message: res.data.msg,
                        });

                    }
                }
            });
        } else if (files.length > 0) {
            var formData = new FormData();
            formData.append('idBlog', id);

            files.forEach(file => {
                formData.append('files[]', file.file);
            });
            formData.append('comment', content);
            axios.post(process.env.REACT_APP_API_URL + 'blogs/comment', formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    Accept: "application/json",
                },
            }).then((res) => {
                if (res.data.check == true) {
                    notyf.open({
                        type: "success",
                        message: "Comment thành công",
                    });
                    window.location.reload();
                } else if (res.data.check == false) {
                    if (res.data.msg) {
                        notyf.open({
                            type: "error",
                            message: res.data.msg,
                        });

                    }
                }
            });
        }
    }

    const config = {
        height: 400,
        width: '100%'
    }
    return (
        <>
            <Layout>
                <>
                    <div className="row">
                        <div className="col-md-1">
                            <button className='btn w-100 btn-primary' onClick={switchCreate}>Tạo</button>
                        </div>
                    </div>
                    <div className="row w-100">
                        <div className={create ? "col-md-9 mt-4" : "col-md"}>
                            {single && (
                                <div class="card mt-3">
                                    <div class="card-header">
                                        <div className="row">
                                       <div className="col-md-11">
                                       <h4 className='text-start flex'>
                                        {single.title}</h4>
                                       </div>
                                       <div className="col-md">
                                       <button onClick={(e)=>deletePost()} className='btn btn-sm w-100 btn-danger'>Xóa</button>

                                       </div>
                                        </div>
                                       
                                        </div>
                                    <div class="card-body">
                                        <div
                                            dangerouslySetInnerHTML={{ __html: single.content }}
                                        />
                                        <div className="flex">
                                            {gallery.length > 0 && gallery.map((item, index) => (
                                                <img key={index} src={`https://test.codingfs.com/images/${item.image}`} className={index > 0 ? 'ms-3' : ''} alt={`Gallery item ${index}`} style={{ width: 'auto', height: '180px' }} />
                                            ))}
                                        </div>
                                        <hr />
                                        <div className="row mt-2">
                                            <h4>Comments</h4>
                                            {comments.length > 0 && comments.map((item, index) => (
                                                <div className="row">
                                                    <div className="col-md-5">
                                                    <h5 className='text-danger'>{item.user.name}</h5>
                                                    <div
                                                        dangerouslySetInnerHTML={{ __html: item.comment }}
                                                    />
                                                    {item.gallery.length > 0 && item.gallery.map((item, index) => (
                                                        <img key={index} src={`https://test.codingfs.com/images/${item.image}`} className={index > 0 ? 'ms-3' : ''} alt={`Gallery item ${index}`} style={{ width: 'auto', height: '180px' }} />
                                                    ))}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                        {create && (

                            <div className="col-md">
                                <div className="col-md">
                                    <div className="row mb-3">
                                        <label htmlFor="title" className="form-label">Comment</label>
                                        <div className="input-group mb-3">
                                            <button
                                                className="btn btn-outline-primary"
                                                type="button"
                                                id="title"
                                                onClick={(e) => submitBlogs()}
                                            >
                                                Thêm
                                            </button>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <JoditEditor
                                            ref={editor}
                                            config={config}
                                            value={content}
                                            tabIndex={1}
                                            onBlur={newContent => setContent(newContent)}
                                        />
                                    </div>
                                    <div className="row mb-3 ps-3">
                                        <Dropzone style={{ width: '90%' }} onChange={updateFiles} accept="image/*" value={files}>
                                            {files.slice(0, 3).map((file) => (
                                                <FileMosaic {...file} preview />
                                            ))}
                                        </Dropzone>
                                    </div>

                                </div>
                            </div>
                        )}
                    </div>
                </>
            </Layout>
        </>
    );
}

export default Single;
