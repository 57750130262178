import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ProSidebarProvider } from "react-pro-sidebar";
import { GoogleOAuthProvider } from '@react-oauth/google';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <GoogleOAuthProvider clientId="161063259833-gtjgvl7u1k1j79gsf9uhk7lv9edkp8bn.apps.googleusercontent.com">
    <ProSidebarProvider>
      <App />
    </ProSidebarProvider>
  </GoogleOAuthProvider>,

);